import React from 'react';
import NavbarDropdown from './NavbarDropdown';
import {
  // dashboardRoutes,
  // appRoutes,
  // pagesRoutes,
  // modulesRoutes,
  // documentationRoutes,
  accountManagement,
  inventoryManagement,
  salesManagement,
  productionManagement
} from 'routes/siteMaps';
// import { Dropdown } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { flatRoutes } from 'helpers/utils';
import NavbarDropdownApp from './NavbarDropdownApp';
// import NavbarDropdownPages from './NavbarDropdownPages';
// import NavbarDropdownModules from './NavbarDropdownModules';
// import { useAppContext } from 'providers/AppProvider';

const NavbarTopDropDownMenus = () => {
  // const {
  //   config: { navbarCollapsed, showBurgerMenu },
  //   setConfig
  // } = useAppContext();

  // const handleDropdownItemClick = () => {
  //   if (navbarCollapsed) {
  //     setConfig('navbarCollapsed', !navbarCollapsed);
  //   }
  //   if (showBurgerMenu) {
  //     setConfig('showBurgerMenu', !showBurgerMenu);
  //   }
  // };

  return (
    <>
      <NavbarDropdown title="Account Management">
        <NavbarDropdownApp items={accountManagement.children} />
      </NavbarDropdown>

      <NavbarDropdown title="Inventory Management">
        <NavbarDropdownApp items={inventoryManagement.children} />
      </NavbarDropdown>

      <NavbarDropdown title="Sales Management">
        <NavbarDropdownApp items={salesManagement.children} />
      </NavbarDropdown>

      <NavbarDropdown title="Production Management">
        <NavbarDropdownApp items={productionManagement.children} />
      </NavbarDropdown>

      {/* <NavbarDropdown title="dashboard">
        {dashboardRoutes.children[0].children.map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown>

      <NavbarDropdown title="app">
        <NavbarDropdownApp items={appRoutes.children} />
      </NavbarDropdown>

      <NavbarDropdown title="pages">
        <NavbarDropdownPages items={pagesRoutes.children} />
      </NavbarDropdown>
      <NavbarDropdown title="modules">
        <NavbarDropdownModules items={modulesRoutes.children} />
      </NavbarDropdown>

      <NavbarDropdown title="documentation">
        {flatRoutes(documentationRoutes.children).map(route => (
          <Dropdown.Item
            key={route.name}
            as={Link}
            className={route.active ? 'link-600' : 'text-500'}
            to={route.to}
            onClick={handleDropdownItemClick}
          >
            {route.name}
          </Dropdown.Item>
        ))}
      </NavbarDropdown> */}
    </>
  );
};

export default NavbarTopDropDownMenus;
