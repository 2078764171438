import React, { useEffect } from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Logout = () => {
  const { logout, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        if (!token) {
          console.error('No token found, cannot logout.');
          navigate('/');
          return;
        }
        localStorage.removeItem('token');
        console.log('Logging out... with token', token);
        const response = await axios.post(
          'https://api.vossgi.com/v1/auth/logout',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/json'
            }
          }
        );
        console.log('Response:', response);
        logout();
      } catch (error) {
        console.error('Logout error:', error);
      }
    };

    logoutUser();
  }, [token, logout]);

  return (
    <div className="text-center">
      <LogoutContent />
    </div>
  );
};

export default Logout;
