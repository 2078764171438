import React from 'react';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';

const AccountDetailsHeader = ({ data }) => {
  const {
    // id,
    // code,
    // name,
    type,
    category,
    legal_name,
    // account_owner_id,
    // credit_limit,
    // balance,
    // price_group_id,
    // payment_method_id,
    // payment_term_id,
    // shipping_method_id,
    // sales_tax_id,
    // score,
    // jbt_number,
    // jbt_date,
    // jbt_rating,
    // website,
    // active,
    created_at
    // updated_at,
    // location_primary,
    // locations
  } = data || {};
  // const {
  //   id: locationId,
  //   code: locationCode,
  //   name: locationName,
  //   type: locationType,
  //   account_id: locationAccountId,
  //   parent_id: locationParentId,
  //   is_primary,
  //   is_billing,
  //   is_shipping,
  //   email,
  //   phone,
  //   street,
  //   city,
  //   state,
  //   zip,
  //   country,
  //   latitude,
  //   longitude,
  //   active: locationActive,
  //   created_at: locationCreatedAt,
  //   updated_at: locationUpdatedAt
  // } = location_primary || {};
  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="g-2">
          <Col>
            <h5 className="mb-2">
              {legal_name} ({type?.toUpperCase()})
            </h5>
            <IconButton
              iconClassName="fs-11 me-1"
              variant="falcon-default"
              size="sm"
              icon="plus"
            >
              Add note
            </IconButton>
            <Dropdown className="d-inline-block ms-2">
              <Dropdown.Toggle
                as={Button}
                variant="falcon-default"
                size="sm"
                className="dropdown-caret-none"
              >
                <FontAwesomeIcon icon="ellipsis-h" />
              </Dropdown.Toggle>

              <Dropdown.Menu align="start">
                <Dropdown.Item as={Link} to="#!">
                  Edit
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="#!">
                  Report
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="#!">
                  Archive
                </Dropdown.Item>
                <Dropdown.Divider as="div" />
                <Dropdown.Item as={Link} to="#!" className="text-danger">
                  Delete user
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="auto">
            <h6 className="text-uppercase text-600 mb-0">
              {category}
              <FontAwesomeIcon icon="user" className="ms-2" />
            </h6>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="border-top">
        <Flex>
          <FontAwesomeIcon
            icon="user"
            className="text-primary me-2"
            transform="down-5"
          />
          <div className="flex-1">
            <p className="mb-0">Customer was created</p>
            <p className="fs-10 mb-0 text-600">{created_at}</p>
          </div>
        </Flex>
      </Card.Body>
    </Card>
  );
};

AccountDetailsHeader.propTypes = {
  data: PropTypes.object.isRequired
};

export default AccountDetailsHeader;
