import React, { useState, useEffect, Suspense } from 'react';
import { Card, Form, Badge } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport
} from '@mui/x-data-grid';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
// import { useNavigate } from 'react-router-dom';

const API_URL = 'https://api.vossgi.com/v1/products';

const columns = [
  {
    field: 'image_url',
    headerName: 'Image',
    flex: 1,
    renderCell: params =>
      params.value ? (
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            width: '30px',
            height: '30px',
            overflow: 'hidden'
          }}
        >
          <img
            src={params.value}
            alt="Item"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '4px',
              transition: 'transform 0.3s ease-in-out'
            }}
            onMouseEnter={e => (e.target.style.transform = 'scale(2)')}
            onMouseLeave={e => (e.target.style.transform = 'scale(1)')}
          />
        </div>
      ) : (
        <></>
      )
  },
  { field: 'sku', headerName: 'SKU', flex: 1 },
  { field: 'name', headerName: 'Name', flex: 2 },
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
    valueGetter: params =>
      params?.name
        ? params.name.charAt(0).toUpperCase() + params.name.slice(1)
        : ''
  },
  { field: 'brand', headerName: 'Brand', flex: 1 },
  {
    field: 'active',
    headerName: 'Status',
    flex: 1,
    renderCell: params => (
      <Badge
        as="span"
        bg={`${params.value === 1 ? 'success' : 'danger'}`}
        style={{
          padding: '0.4em 0.8em',
          fontSize: '0.7rem',
          fontStyle: 'normal',
          textTransform: 'capitalize'
        }}
      >
        {params.value === 1 ? 'Active' : 'Inactive'}
      </Badge>
    )
  },
  { field: 'variant_count', headerName: 'Number of Variants', flex: 1 },
  { field: 'updated_at', headerName: 'Updated At', flex: 1 }
];

const Products = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // const navigate = useNavigate();

  const CustomExportButton = () => {
    const handleExport = async () => {
      let allData;
      try {
        const t = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}`, {
          headers: {
            Authorization: `Bearer ${t}`
          },
          params: {
            page: 0,
            per_page: 99999
          }
        });

        allData = response.data?.data || [];
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      const csv = Papa.unparse(allData);
      const blob = new Blob([csv], { type: 'text/csv' });
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const fileName = `accounts_data_${timestamp}.csv`;
      saveAs(blob, fileName);
    };

    return (
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="external-link-alt"
        transform="shrink-3"
        onClick={handleExport}
      >
        <span className="d-none d-sm-inline-block ms-1">Export All</span>
      </IconButton>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px'
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <CustomExportButton />
      </GridToolbarContainer>
    );
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}`, {
        headers: {
          Authorization: `Bearer ${t}`
        },
        params: {
          page: page + 1,
          per_page: pageSize,
          ...(searchQuery &&
            searchQuery.length > 1 && {
              search: searchQuery,
              search_fields: 'sku,name,brand'
            })
        }
      });
      console.log(response.data?.data[0]);
      const transformedData = response.data?.data || [];

      console.log('😀', transformedData[0]);

      setData(transformedData);
      setRowCount(response.data?.meta?.total || 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, searchQuery]);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // const handleRowClick = id => {
  //   navigate(`/accounts/account-details/${id}`);
  // };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Card className="mb-3">
        <Card.Header className="mb-1 pb-1">
          Products{' '}
          <span className="text-muted ms-1">
            ({rowCount} <span className="small">records found</span>)
          </span>
        </Card.Header>
        <Card.Body className="pt-1">
          <div className="mb-1 flex">
            <Form.Group>
              <Form.Control
                type="text"
                name="filter"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex', height: '75vh', width: '100%' }}>
            <DataGrid
              rows={data}
              columns={columns}
              pagination
              density="compact"
              pageSizeOptions={[100]}
              pageSize={pageSize}
              paginationMode="server"
              rowCount={rowCount}
              // onRowClick={e => handleRowClick(e.row.id)}
              loading={loading}
              onPaginationModelChange={pagination => {
                setPage(pagination.page);
                setPageSize(pagination.pageSize);
              }}
              slots={{
                toolbar: CustomToolbar
              }}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#2c7be5',
                  fontSize: '0.8rem',
                  fontWeight: 'bold',
                  [theme => theme.breakpoints.up('lg')]: {
                    fontSize: '0.8rem'
                  }
                },
                '& .MuiDataGrid-row': {
                  fontSize: '0.8rem',
                  [theme => theme.breakpoints.up('lg')]: {
                    fontSize: '0.7rem'
                  }
                },
                '& .MuiDataGrid-footerContainer': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: '0.8rem'
                },
                '& .MuiPagination-root': {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                },
                '& .MuiDataGrid-toolbarContainer': {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '2px',
                  fontSize: '0.8rem',
                  color: 'black',
                  minHeight: '32px'
                },
                '& .MuiButtonBase-root': {
                  color: 'black',
                  fontSize: '0.8rem',
                  minHeight: '24px'
                },
                '& .MuiSvgIcon-root': {
                  color: 'black',
                  fontSize: '1rem'
                },
                '& .MuiTablePagination-selectLabel': {
                  display: 'none', // Hides "Rows per page" label
                  paddingTop: '12px'
                },
                '& .MuiTablePagination-displayedRows': {
                  display: 'none', // Hides record count text
                  paddingTop: '12px'
                },
                '& .MuiTablePagination-select': {
                  display: 'none' // Hides "per page" dropdown
                },
                '& .MuiPagination-ul': {
                  justifyContent: 'flex-end' // Ensures pagination buttons align properly
                }
              }}
            />
          </div>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </Suspense>
  );
};

export default Products;
