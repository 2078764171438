import React, { useState, useEffect, Suspense } from 'react';
import AccountDetailsHeader from './AccountDetailsHeader';
// import AccountInfo from './AccountInfo';
// import AccountLog from './AccountLog';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const API_URL = 'https://api.vossgi.com/v1/accounts';

const AccountDetails = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const { accountId } = useParams();

  if (!accountId) {
    return <>No Account ID!!!</>;
  }
  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/${accountId}`, {
        headers: {
          Authorization: `Bearer ${t}`
        }
      });
      setData(response.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(data);
  if (loading) {
    return <>Loading...</>;
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AccountDetailsHeader data={data} />
      {/* <AccountInfo data={data} /> */}
      {/* <AccountLog /> */}
    </Suspense>
  );
};

export default AccountDetails;
